<template>
  <div class="list-info">
    <top-bar :title="'出租屋'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入房号、姓名搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row type="flex" justify="center">
        <van-col span="8">
          <p @click="gridShow = !gridShow" :style="{color:gridShow?'#387FF5':'#666666'}">
            {{gridName}}
            <img :src="require(`@/assets/img/${gridShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="subAreaShow = !subAreaShow" :style="{color:subAreaShow?'#387FF5':'#666666'}">
            {{subAreaName}}
            <img :src="require(`@/assets/img/${subAreaShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8" v-if="showType">
          <p @click="typeShow = !typeShow" :style="{color:typeShow?'#387FF5':'#666666'}">
            {{houseType}}
            <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="gridShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="gridList" value-key="label" @confirm="gridConfirm" @cancel="gridCancel"  />
    </van-popup>
    <van-popup v-model="subAreaShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="subAreaList" value-key="name" @confirm="subAreaConfirm" @cancel="subAreaCancel"  />
    </van-popup>
    <van-popup v-model="typeShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeCancel"  />
    </van-popup>
    <div class="add-box" @click="goAdd">
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/add.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="item in dataList" :key="item.id" @click="goHouse(item.id)">
          <div class="title">
            <div class="title-left">
              {{ (item.householder || '空') + ' | ' + (item.householderMobile || '未知')}}
            </div>
            <div class="title-right">
              {{item.subareaName || '未知'}}
            </div>
          </div>
          <div class="content">
            <img v-if="item.liveNum == 0" :src="imgKong" class="content-img" />
            <img v-else-if="item.useType == 2" :src="imgZu" class="content-img" />
            <img v-else-if="item.useType == 1" :src="imgZhu" class="content-img" />
            <div class="content-text">
              <div class="top-text">{{item.fullName}}</div>
              <div class="bottom-text">{{'居住总人数' + item.resCount + '人 租客' + item.migrantNum + '人'}}</div>
            </div>
            <van-icon name="arrow" color="#666" class="content-icon"/>
          </div>
        </div>
      </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {mobile} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {mapMutations} from 'vuex';
export default {
  name: 'numWarning',
  components :{
    topBar
  },
  data() {
    return {
      dataForm: {
        gridId: '',
        subarea: '',
        rentHouse: 0,
        type: ''
      },
      gridName: '所属网格',
      subAreaName: '所在小区',
      houseType: '房屋属性',
      loading: false,
      finished: false,
      subAreaShow: false,
      gridShow: false,
      typeShow: false,
      pullLoading: false,
      page: 0,
      limit: 10,
      searchValue: '',
      subAreaList: [],
      gridList: [],
      typeList: [{ value: 0, label: '所有' }, { value: 1, label: '预警房' }],
      totalCount: 0,
      dataList: [],
      imgKong: getImageStream('files/wx/images/content/kong.png'),
      imgZu: getImageStream('files/wx/images/content/zu.png'),
      imgZhu: getImageStream('files/wx/images/content/zhu.png'),
      showType: false
    };
  },
  methods: {
    ...mapMutations,
    goHouse(houseId) {
      this.$store.commit("setHouseId", houseId);
      this.$router.push({
        path: '/house-view-details',
        query: {
          id:houseId,
        }
      })
    },
    goAdd () {
      this.$router.push('/house-add')
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          searchValue: this.searchValue,
          gridId: this.dataForm.gridId,
          subarea: this.dataForm.subarea,
          rentHouse: this.dataForm.rentHouse,
          type: this.dataForm.type
        })
      }).then(({data})=> {
        if (data && data.code == 0) {
          if (data.page) {
            this.pullLoading = false
            this.totalCount = data.page.totalCount
          }
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          this.dataList.map(item => {
            item['monile'] = mobile(item.userMobile)
          })
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getGridList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/grid/info/listByUser'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$orgId
        })
      }).then(({data})=> {
        if (data && data.code === 0) {
          this.gridList = data.grids
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    // 获取小区列表
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.subAreaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    onSearch() {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.searchValue = ''
      this.getDataList()
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    gridConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.gridName = value.label
      this.dataForm.gridId = value.value
      this.finished = false
      this.gridShow = false
      this.getDataList()
    },
    gridCancel() {
      this.page = 0
      this.dataList = []
      this.dataForm.gridId = ''
      this.gridName = '所属网格'
      this.gridShow = false
      this.getDataList()
    },
    subAreaConfirm (value) {
      this.page = 0
      this.dataList = []
      this.subAreaName = value.name.length > 7 ? value.name.slice(0,7) + '...' : value.name
      this.dataForm.subarea = value.id
      this.finished = false
      this.getDataList()
      this.subAreaShow = false
    },
    subAreaCancel() {
      this.page = 0
      this.dataList = []
      this.dataForm.subarea = ''
      this.subAreaName = '所在小区'
      this.subAreaShow = false
      this.getDataList()
    },
    typeConfirm (value) {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.houseType = value.label
      this.dataForm.rentHouse = value.value
      this.getDataList()
      this.typeShow = false
    },
    typeCancel() {
      this.page = 0
      this.dataList = []
      this.dataForm.rentHouse = 0
      this.houseType = '房屋属性'
      this.typeShow = false
      this.getDataList()
    }
  },
  mounted () {
    if (!this.$globalData.warningType) this.showType = true
    if (this.$globalData.warningType == 1) {
      document.title = '群租预警'
      this.dataForm.rentHouse = 1
    } else if (this.$globalData.warningType == 2) {
      document.title = '房租到期预警'
      this.dataForm.rentHouse = 2
    } else if (this.$globalData.warningType == 3) {
      document.title = '出租备案预警'
      this.dataForm.type = 'rentOutRecord'
    } else if (this.$globalData.warningType == 4) {
      document.title = '出租空置预警'
      this.dataForm.type = 'czZero'
    } else if (this.$globalData.warningType == 5) {
      document.title = '自住空置预警'
      this.dataForm.type = 'zzZero'
    } else if (this.$globalData.warningType == 6) {
      document.title = '空置非空预警'
      this.dataForm.type = 'vacantNotVacant'
    }
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.userId = this.$globalData.userInfo.userId
    this.getDataList()
    this.getGridList()
    this.getSubareaList()

  }
}
</script>
